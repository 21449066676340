<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-card-section class="q-gutter-sm">
        <q-select
          :options="listAsrama"
          v-model="selAsrama"
          outlined
          label="Asrama"
          @input="getListKamar"
        ></q-select>
        <q-input outlined label="Cari Kamar" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-scroll-area style="height:calc(100vh - 242px);">
        <q-list bordered separator class="bg-indigo-1">
          <q-item
            clickable
            v-ripple
            v-for="(val, i) in filKamar"
            :key="i"
            @click="showAnggotaKamar(val)"
          >
            <q-item-section>
              <strong>Kamar</strong>
              <br />
              <a>Musyrif</a>
              <br />
              <a>Ketua Kamar</a>
            </q-item-section>
            <q-item-section side top>
              <strong>{{ val.kamar }}</strong>
              <br />
              <a>{{ val.musyrif }}</a>
              <br />
              <a>{{ val.ketua }}</a>
            </q-item-section>
          </q-item>
        </q-list>
      </q-scroll-area>
    </q-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listAsrama: [],
      selAsrama: null,
      listKamar: [],
      searchTerm: "",
    };
  },
  computed: {
    filKamar() {
      if (this.searchTerm == "") return this.listKamar;
      else {
        return this.listKamar.filter((val) => {
          let cNama =
            val.kamar.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !=
            -1;
          return cNama;
        });
      }
    },
  },
  async mounted() {
    await this.getListAsrama();
    if (parseInt(this.$route.params.id_asrama) < 0) {
      this.selAsrama = this.listAsrama[0];
    } else {
      this.selAsrama = this.listAsrama.find(
        ({ value }) => value == this.$route.params.id_asrama
      );
      console.log(this.selAsrama);
    }
    await this.getListKamar();
  },
  methods: {
    showAnggotaKamar(val) {
      console.log(val);
      this.$router.push(`/pengaturananggotakamar/${val.id}`);
    },
    async getListAsrama() {
      let resp = await this.$http.get("/pengaturan/kamar/getlistasrama");
      this.listAsrama = resp.data;
    },
    async getListKamar() {
      let resp = await this.$http.get(
        `/pengaturan/kamar/${this.selAsrama.value}`
      );
      this.listKamar = resp.data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
